import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faTimes,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';
import {
  SearchInputField,
  StaticSearchLink,
  ResultsList,
  ClearButton,
} from './styles.css';

const dropShadow = {
  boxShadow: '0px 4px 8px 0px rgb(207, 213, 219)',
};

const dropShadowDark = {
  boxShadow: '0px',
};

const resultsWrapper = {
  boxShadow: '0px 4px 8px 0px rgb(207, 213, 219)',
  backgroundColor: '#fff',
};

// Search component
export default class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: [],
      ShowdropShadow: this.props.dropShadow,
    };
  }

  clearSearch() {
    this.setState({
      query: '',
      results: [],
    });
  }

  render() {
    const { ShowdropShadow } = this.state;
    return (
      <React.Fragment>
        <Box position="relative">
          <Box
            display="flex"
            style={ShowdropShadow ? dropShadow : dropShadowDark}
          >
            <Box width="100%" display="flex" alignItems="center">
              <label htmlFor="search" style={{ display: 'none' }}>
                Search jobs
              </label>
              <SearchInputField
                type="text"
                id="search"
                name="jobSearch"
                placeholder="Search for a job..."
                value={this.state.query}
                onChange={this.search}
              />
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                bgcolor="background.default"
              >
                {this.state.query.length > 0 && (
                  <ClearButton onClick={this.clearSearch.bind(this)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </ClearButton>
                )}
              </Box>
            </Box>
            <Box flexShrink={1} width="60px">
              <StaticSearchLink
                to="/opportunities"
                state={{ searchTerm: this.state.query }}
              >
                <span className="aria-hidden">Opportunities</span>
                <FontAwesomeIcon icon={faCaretRight} />
              </StaticSearchLink>
            </Box>
          </Box>
          <Box position="absolute" width="100%" style={resultsWrapper}>
            {this.state.results.length > 0 && (
              <ResultsList>
                {this.state.results.map(page => (
                  <li key={page.id}>
                    <Link to={page.slug}>
                      {page.title}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </li>
                ))}
              </ResultsList>
            )}
            {this.state.results.length == 0 && this.state.query.length > 3 && (
              <Box p={1}>
                <Typography variant="h5">
                  We couldn&apos;t find an exact match
                </Typography>
                <Box py={[0.5]}>
                  <Typography>
                    Don&apos;t worry, sometimes our roles could differ in name
                    to what you&apos;ve searched for. Try searching again
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Link to="/opportunities">
                    <Typography variant="subtitle2" component="span">
                      Explore all OVOs roles
                    </Typography>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };
}

SearchField.propTypes = {
  searchIndex: PropTypes.object,
  dropShadow: PropTypes.bool,
};
