import styled from 'styled-components';
import { Link } from 'gatsby';

export const SearchInputField = styled.input`
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border-left: 1px;
  border-right: 0px;
  border-top: 1px;
  border-bottom: 1px;
  border-color: #d1d6de;
  border-style: solid;
  width: 100%;
  margin: 0px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  outline: 0;
`;

export const StaticSearchLink = styled(Link)`
  background-color: #0a9828;
  border-left: 0px;
  border-right: 1px;
  border-top: 1px;
  border-bottom: 1px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  svg {
    font-size: 2.2rem;
    color: #fff;
  }
  &:hover {
    background-color: #006945;
    border-left: 1px;
    border-right: 1px;
    border-top: 1px;
    border-bottom: 1px;
    border-color: transparent;
    border-style: solid;
    svg {
      color: #fff;
    }
  }
`;

export const ResultsList = styled.ul`
  margin: 0;
  padding: 0px;
  max-height: 250px;
  overflow-y: scroll;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    background-color: #fff;
    transition: all 0.3s ease-out;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    a {
      color: #000;
      text-decoration: none;
      transition: all 0.3s ease-out;
      font-weight: normal;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 15px 18px;
    }
    svg {
      color: #0a9828;
      font-size: 14px;
    }
  }
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: #d1d6de;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  svg {
    transition: all 0.3s ease-out;
    color: #000;
  }
  &:hover {
    svg {
      color: #0a9828;
    }
  }
`;
