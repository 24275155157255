import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import Search from 'components/search';
import TotalJobsLight from 'components/totaljobs/TotalJobsLight';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

export const Background = styled.div`
  background-size: contain;
  background-image: ${props => `url(${props.image})`};
`;

const styles = theme => ({
  hero: {
    height: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      minHeight: '450px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '600px',
      paddingTop: '0px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '800px',
      paddingTop: '0px',
    },
    [theme.breakpoints.down('md')]: {
      backgroundPositionX: 'calc(100% + 230px) !important',
    },
  },
  heroTitle: {
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    color: theme.palette.text.black,
  },
  subTextBoxWrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '-40px',
    },
  },
  subTextBox: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0 100%)',
    '-webkit-clip-path': 'polygon(0 10%, 100% 0, 100% 100%, 0 100%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'polygon(0 15%, 100% 0, 100% 100%, 0 100%)',
      '-webkit-clip-path': 'polygon(0 15%, 100% 0, 100% 100%, 0 100%)',
    },
    [theme.breakpoints.up('md')]: {
      clipPath: 'polygon(0 25%, 100% 0, 100% 100%, 0 100%)',
      '-webkit-clip-path': 'polygon(0 25%, 100% 0, 100% 100%, 0 100%)',
    },
  },
  heroSubLine: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
});

function HeroFullScreen(props) {
  const { heroTitle, heroImage, search, spotlightDept, depts, classes } = props;
  return (
    <Background
      style={{ backgroundSize: 'contain', backgroundPosition: 'top right' }}
      className={classes.hero}
      image={heroImage}
    >
      <Container>
        <Box
          component={Grid}
          container
          justifyContent="space-between"
          alignItems="center"
          mt={[4, 4, 8]}
        >
          <Grid item xs={12} md={6} lg={5}>
            <Box mb={3}>
              <RichText
                render={heroTitle}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </Box>
            {search && (
              <Box>
                <Search dropShadow={false} />
                <TotalJobsLight spotlightDept={spotlightDept} depts={depts} />
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
    </Background>
  );
}

HeroFullScreen.propTypes = {
  heroTitle: PropTypes.array.isRequired,
  heroImage: PropTypes.string.isRequired,
  spotlightDept: PropTypes.object,
  depts: PropTypes.object,
  search: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(HeroFullScreen);
