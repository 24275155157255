import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from '@material-ui/core/Box';
import HeroFullScreen from 'components/hero/HeroFullScreen';
import Spacer from 'components/spacer';
import { navigate } from '@reach/router';
import { withPreview } from 'gatsby-source-prismic';
import LatestJobs from 'containers/home/latestjobs';
import {
  ImageLeftTextRight,
  FullWidthTextImage,
  ExternalLinksGrid,
  ThreeColumn,
  TwoColumn,
  Locations,
  StackedImageLinks,
  VideoBlock,
  TitleCaption,
} from 'components/slices';

function Home(props) {
  const { data } = props;

  const doc = data.prismicHomepage;
  const global = data.prismicGlobalLayout;
  const depts = data.allPrismicDepartment;

  if (!doc || !global || !depts) return null;
  var sendSpotlight = null;

  if (
    global.data.spotlight_a_department &&
    global.data.spotlight_a_department
  ) {
    sendSpotlight = global.data.select_which_department_to_spotlight;
  }
  const CallSearch = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].node.gh_Id == nameKey) {
        return myArray[i];
      }
    }
  };

  useEffect(() => {
    if (props.location.search) {
      const search = props.location.search;
      const match = search.search('gh_jid=');
      if (match > 0) {
        var ghid = search.replace('?gh_jid=', '');
        const ghSrc = ghid.search('&gh_src=');
        if (ghSrc > 0) {
          var res = ghid.split('&');
          const getSlug = CallSearch(res[0], data.AllGreenHouseJobs.edges);
          navigate(`/${getSlug.node.fields.slug}/${res[0]}?${res[1]}`);
        } else {
          navigate(`/job_ghid/${ghid}`);
        }
      }
    }
  }, []);
  return (
    <Layout headerOpacity="0.9">
      <Box component="div">
        <HeroFullScreen
          heroTitle={doc.data.banner_title.raw}
          heroImage={doc.data.banner_image.url}
          search={true}
          spotlightDept={sendSpotlight}
          depts={depts}
        />
        {doc.data.body && (
          <React.Fragment>
            {doc.data.body.map((item, i) => {
              switch (item.slice_type) {
                case 'image_left_text_right':
                  return (
                    <Spacer key={i}>
                      <ImageLeftTextRight data={item.primary} />
                    </Spacer>
                  );
                case 'full_width_text_image':
                  return (
                    <Spacer key={i}>
                      <FullWidthTextImage data={item.primary} />
                    </Spacer>
                  );
                case 'external_links_grid':
                  return (
                    <Spacer key={i}>
                      <ExternalLinksGrid
                        data={item.primary}
                        fields={item.items}
                      />
                    </Spacer>
                  );
                case 'three_column':
                  return (
                    <Spacer key={i}>
                      <ThreeColumn data={item.primary} fields={item.items} />
                    </Spacer>
                  );
                case 'two_column':
                  return (
                    <Spacer key={i}>
                      <TwoColumn data={item.primary} fields={item.items} />
                    </Spacer>
                  );
                case 'locations':
                  return (
                    <Spacer key={i}>
                      <Locations data={item.primary} fields={item.fields} />
                    </Spacer>
                  );
                case 'latest_jobs_block':
                  return (
                    <Spacer key={i}>
                      <LatestJobs
                        data={data.LatestGreenHouseJobs}
                        fields={item.primary}
                        showMoreButton={true}
                      />
                    </Spacer>
                  );
                case 'stacked_image_links':
                  return (
                    <Spacer key={i}>
                      <StackedImageLinks
                        data={item.primary}
                        fields={item.fields}
                      />
                    </Spacer>
                  );
                case 'video_block':
                  return (
                    <Spacer key={i}>
                      <VideoBlock data={item.primary} />
                    </Spacer>
                  );
                case 'title_and_caption':
                  return (
                    <Spacer key={i}>
                      <TitleCaption data={item.primary} />
                    </Spacer>
                  );
                default:
                  return null;
              }
            })}
          </React.Fragment>
        )}
      </Box>
    </Layout>
  );
}

export default withPreview(Home);

Home.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  query HomePageQuery {
    prismicGlobalLayout {
      data {
        spotlight_a_department
        select_which_department_to_spotlight {
          type
          uid
          slug
          id
        }
      }
    }
    allPrismicDepartment {
      edges {
        node {
          url
          uid
          id
          data {
            greenhouse_id
            title {
              html
              raw
              text
            }
          }
        }
      }
    }
    prismicHomepage {
      prismicId
      data {
        banner_title {
          text
          raw
        }
        banner_image {
          url
        }
        body {
          ... on PrismicHomepageBodyTitleAndCaption {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
            }
          }
          ... on PrismicHomepageBodyVideoBlock {
            slice_type
            primary {
              video_url {
                text
                raw
                html
              }
              title {
                text
                raw
              }
              content {
                raw
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicHomepageBodyImageLeftTextRight {
            slice_type
            primary {
              body {
                text
                raw
              }
              caption {
                text
              }
              image {
                alt
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageBodyFullWidthTextImage {
            slice_type
            primary {
              body {
                text
              }
              caption {
                text
              }
              image {
                url
                alt
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageBodyExternalLinksGrid {
            id
            slice_type
            primary {
              title {
                text
              }
              link_title {
                text
              }
              caption {
                text
              }
              link {
                url
              }
            }
            items {
              body {
                text
                html
              }
              image {
                url
                alt
              }
              link {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageBodyThreeColumn {
            slice_type
            primary {
              title {
                text
              }
              caption {
                text
              }
              footer_link {
                raw
              }
              footer_link_text
            }
            items {
              body {
                text
                raw
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicHomepageBodyTwoColumn {
            slice_type
            primary {
              title {
                text
              }
              caption {
                text
              }
            }
            items {
              body {
                text
              }
              image {
                url
                alt
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageBodyLatestJobsBlock {
            slice_type
            primary {
              link_text {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
    LatestGreenHouseJobs: allGreenhouseJob(
      limit: 6
      sort: { fields: updated_at, order: DESC }
      filter: { gh_Id: { ne: 3427618 } }
    ) {
      edges {
        node {
          title
          gh_Id
          fields {
            slug
          }
          departments {
            name
          }
          offices {
            name
          }
          location {
            name
          }
          updated_at(fromNow: true)
        }
      }
    }
    AllGreenHouseJobs: allGreenhouseJob(filter: { gh_Id: { ne: 3427618 } }) {
      edges {
        node {
          id
          gh_Id
          internal_job_id
          title
          offices {
            fields {
              slug
            }
          }
          departments {
            fields {
              slug
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
