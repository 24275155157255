import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import getJobPostPathname from 'helpers/getJobPostPathname';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BtnLg from 'components/buttons/btnlg';

import { RichText } from 'prismic-reactjs';

import { DepartmentLiveJobs } from 'components/slices';

const styles = theme => ({
  content: {
    background: theme.palette.background.lgrey,
    [theme.breakpoints.up('sm')]: {
      clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
      '-webkit-clip-path': 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
    },
  },
  Link: {
    cursor: 'pointer',
    display: 'inline-block',
    borderBottom: `2px solid ${theme.palette.text.green}`,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderBottomColor: 'transparent',
      color: theme.palette.text.green,
    },
  },
  title: {
    color: theme.palette.text.black,
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
});

function LatestJobs(props) {
  const { data, fields, classes, showMoreButton } = props;
  return (
    <Box className={classes.greyBg} pb={4}>
      <Container>
        <DepartmentLiveJobs
          latestJobs={true}
          jobData={data}
          showMoreButton={showMoreButton}
        />
      </Container>
    </Box>
  );
}

LatestJobs.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object,
  showMoreButton: PropTypes.bool,
};

export default withStyles(styles)(LatestJobs);
