import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SearchField from './searchfield';

const Query = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`;

const Search = props => (
  <StaticQuery
    query={`${Query}`}
    render={data => (
      <SearchField
        searchIndex={data.siteSearchIndex.index}
        dropShadow={props.dropShadow}
      />
    )}
  />
); 

Search.propTypes = {
  dropShadow: PropTypes.bool,
};

export default Search;
