import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = theme => ({
  highlight: {
    color: theme.palette.text.green,
    transition: 'all 0.2s ease-in-out',
    textDecoration: 'none',
    fontWeight: '700',
    '&:hover': {
      color: theme.palette.text.dgreen,
    },
  },
});

function TotalJobsLight(props) {
  const { data, spotlightDept, depts, classes } = props;
  const [spotlightObj, setSpotlightObj] = useState([]);

  useEffect(() => {
    if (spotlightDept && depts) {
      setSpotlightObj(findNodeWithUid(depts.edges, spotlightDept.uid));
    }
  }, []);

  const findNodeWithUid = (edges, targetUid) => {
    for (let edge of edges) {
      if (edge.node && edge.node.uid === targetUid) {
        return edge.node;
      }
    }
    return null;
  };

  return (
    <React.Fragment>
      <Box display="flex" mt={[1, 3, 3]}>
        <Typography variant="body1" component="p">
          <Typography component="span">
            Right now we are hiring for{' '}
            <Link to="/opportunities" className={classes.highlight}>
              {data.allGreenhouseJob.totalCount} roles in{' '}
              {data.allGreenhouseOffice.totalCount} locations
            </Link>
          </Typography>
        </Typography>
      </Box>
    </React.Fragment>
  );
}

TotalJobsLight.propTypes = {
  data: PropTypes.object.isRequired,
  spotlightDept: PropTypes.object,
  classes: PropTypes.object,
};

TotalJobsLightWithQuery.propTypes = {
  classes: PropTypes.object,
  spotlightDept: PropTypes.object,
  depts: PropTypes.object,
};

const Query = graphql`
  query {
    allGreenhouseJob(filter: { gh_Id: { ne: 3427618 } }) {
      totalCount
    }
    allGreenhouseOffice {
      totalCount
    }
  }
`;

function TotalJobsLightWithQuery(props) {
  const { classes, spotlightDept, depts } = props;
  return (
    <StaticQuery
      query={`${Query}`}
      render={data => (
        <TotalJobsLight
          data={data}
          spotlightDept={spotlightDept}
          depts={depts}
          classes={classes}
        />
      )}
    />
  );
}

//export default TotalJobsLightWithQuery;
export default withStyles(styles)(TotalJobsLightWithQuery);
